import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import Loader from '../../components/Loader/Loader';
import { useDropzone } from 'react-dropzone';
import Wrapper, {
  BannerWrapper,
  OnboardingWrapper,
  OnboardingContainer,
} from '../Auth/Auth.style';
import Navbar from 'components/UI/Navbar/Navbar';
import PropertyService from 'service/PropertyService';
import { notification } from 'antd';
import {
  addImage,
  deleteImage,
  setThumbnail,
  updateOnboardingStage,
} from '../../features/placeSlice';
import { IMAGE_CATEGORY_OPTIONS } from 'settings/constant';
import styles from './PhotoUpload.module.css';
import { isEmpty } from 'lodash';

const PhotoUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const hostPropertyId = query.get('id');
  const { images, thumbnail, propertyId, onboardingStage } = useSelector(
    (state) => ({
      images: state.images.imageUrlList,
      thumbnail: state.images.thumbnail,
      propertyId: state.propertyId,
      onboardingStage: state.onboardingStage,
    }),
  );
  const [localImages, setLocalImages] = useState([]); // newly uploaded images
  const [imageCategories, setImageCategories] = useState({});
  const [deletedImages, setDeletedImages] = useState([]); // track deleted images
  const [newThumbnail, setNewThumbnail] = useState(
    Object.values(thumbnail).length > 0 ? Object.values(thumbnail)[0] : '',
  );
  const [error, setError] = useState('');
  const [, setIsLoading] = useState(false);
  const MAX_SIZE = 5 * 1024 * 1024; // 5MB;
  const MAX_UPLOAD_COUNT = 25;
  const MIN_UPLOAD_COUNT = 5;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.webp'],
    },
    disabled: localImages.length >= MAX_UPLOAD_COUNT,
    onDrop: async (files) => {
      const count = files.length + localImages.length;
      const oversizedFiles = files.filter((file) => file.size > MAX_SIZE);
      if (count > MAX_UPLOAD_COUNT) {
        setError(`You can upload a maximum of ${MAX_UPLOAD_COUNT} photos.`);
        return;
      } else if (oversizedFiles.length > 0) {
        setError(
          `One or more photo(s) you uploaded is more than 5MB: ${oversizedFiles.map((file) => file.name).join(', ')}`,
        );
        return;
      } else setError('');
      setLocalImages((prev) => [...prev, ...files]);
    },
  });

  const handleDeleteImage = (category, url) => {
    setDeletedImages([...deletedImages, { category, url }]);
    dispatch(deleteImage({ category, url }));
  };

  const handleCategoryChange = (e, imageName) => {
    setImageCategories({
      ...imageCategories,
      [imageName]: e.target.value,
    });
  };

  const handleCategoryEdit = (url, newCategory) => {
    Object.keys(images).forEach((category) => {
      if (images[category].includes(url)) {
        dispatch(deleteImage({ category, url }));
        dispatch(addImage({ category: newCategory, imageUrl: url }));
      }
    });
  };

  const handleDeleteLocalImage = (imageName) => {
    setLocalImages(localImages.filter((image) => image.name !== imageName));
    const { [imageName]: _, ...rest } = imageCategories;
    setImageCategories(rest);
  };

  const handleSetThumbnail = (imageIdentifier) => {
    setNewThumbnail(imageIdentifier);
    dispatch(
      setThumbnail({ [imageCategories[imageIdentifier]]: imageIdentifier }),
    );
  };

  const nextHandler = async () => {
    if (localImages.length < MIN_UPLOAD_COUNT) {
      setError('Please upload at least 5 images');
      return;
    }
    localImages.forEach((image) => {
      const category = imageCategories[image.name];
      if (!category) {
        setError(`Please select a category for all the images`);
        return;
      }
    });
    if (Object.values(thumbnail).length === 0) {
      setError('Please select a thumbnail image');
      return;
    }
    try {
      let finalThumbnailImage = localImages.filter(
        (image) => newThumbnail === image.name,
      )[0];
      const thumbnail = {
        [imageCategories[newThumbnail]]: finalThumbnailImage,
      };
      let photos = {};
      localImages.forEach((image) => {
        const category = imageCategories[image.name] || 'other';
        if(!photos[category]) {
          photos[category] = [];
        }
        photos[category].push(image);
      });
      setIsLoading(true);
      const res = await PropertyService.uploadPhotos(
        photos,
        thumbnail,
        propertyId,
      );
      setIsLoading(false);
      if (res.result) {
        dispatch(updateOnboardingStage(2));
        navigate('/property-listing');
      }
    } catch (error) {
      console.log('Error: ', error);
      notification.error({
        message: 'Photo upload failed',
        description: 'An error occurred, please try again.',
      });
      setIsLoading(false);
      return;
    }
    console.log('save button is called');
  };

  const updateHandler = async () => {

    const allImagesLength = () => {
      let length = 0;
      if (!isEmpty(images)) {
        Object.keys(images).forEach((key) => {
          length += images[key].length;
        });
      }
      if (localImages.length > 0) {
        length += localImages.length;
      }
      return length;
    };

    if (allImagesLength() < MIN_UPLOAD_COUNT) {
      setError('Please upload at least 5 images');
      return;
    }
    localImages.forEach((image) => {
      const category = imageCategories[image.name];
      if (!category) {
        setError(`Please select a category for all the images`);
        return;
      }
    });
    if (Object.values(thumbnail).length === 0) {
      setError('Please select a thumbnail image');
      return;
    }
    let finalThumbnailPhoto = null;
    let finalThumbnailUrl = null;
    const addImages = {};
    const existingImagesCategoryUpdates = {};

    // Prepare new local images and their categories
    localImages.forEach((image) => {
      const category = imageCategories[image.name] || 'other';
      if (!addImages[category]) {
        addImages[category] = [];
      }
      addImages[category].push(image);
    });

    // Prepare existing images and their categories
    Object.keys(images).forEach((category) => {
      if (!existingImagesCategoryUpdates[category]) {
        existingImagesCategoryUpdates[category] = [];
      }
      images[category].forEach((url) => {
        existingImagesCategoryUpdates[category].push(url);
      });
    });

    // Prepare the final thumbnail
    if (localImages.some((image) => image.name === newThumbnail)) {
      finalThumbnailPhoto = localImages.find(
        (image) => image.name === newThumbnail,
      );
    } else {
      Object.keys(images).forEach((category) => {
        if (images[category].includes(newThumbnail)) {
          finalThumbnailUrl = {
            [category]: newThumbnail,
          };
        }
      });
    }

    // Prepare deleted images
    const deleteImagesUrl = deletedImages.map((image) => image.url);
    const finalThumbnail = finalThumbnailPhoto
      ? {
          [imageCategories[newThumbnail]]: finalThumbnailPhoto,
        }
      : {};

    try {
      setIsLoading(true);
      const res = await PropertyService.updatePhotos(
        addImages,
        existingImagesCategoryUpdates,
        finalThumbnail,
        finalThumbnailUrl,
        deleteImagesUrl,
        propertyId,
      );
      setIsLoading(false);
      if (res.result) {
        navigate(`/acknowledgement?id=${hostPropertyId}`);
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: 'Photo upload failed',
        description: 'An error occurred, please try again.',
      });
    }
  };

  return (
    <>
      <Wrapper>
        <OnboardingWrapper>
          <Navbar />

          <OnboardingContainer>
            <h1>Time to show off your property!</h1>
            <p style={{ textAlign: 'center' }}>
              Add a minimum of {MIN_UPLOAD_COUNT} photos that showcase your
              property, amenities & surroundings best (More the merrier)!
            </p>

            <div {...getRootProps({ className: 'dropzone' })}>
              <form className={styles.uploadContainer}>
                <input {...getInputProps()} />
                <p style={{ marginBottom: 0 }}>
                  Drag 'n' drop your photos here, or click to select files from
                  your device.
                </p>
              </form>
            </div>
            {error && (
              <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
            )}
            <div className={styles.container}>
              <div className={styles.imageContainer}>
                {localImages.length > 0 && (
                  <div>
                    <h2 className={styles.categoryHeader}>New Images</h2>
                    <div className={styles.imageRow}>
                      {localImages.map((image) => (
                        <div key={image.name} className={styles.imageWrapper}>
                          <div className={styles.imageOverlayWrapper}>
                            <img
                              src={URL.createObjectURL(image)}
                              alt="preview"
                              className={styles.image}
                            />
                            {newThumbnail === image.name && (
                              <div className={styles.thumbnailOverlay}>
                                Primary
                              </div>
                            )}
                            <button className={styles.ellipsisButton}>
                              ...
                            </button>
                            <div className={styles.dropdownContent}>
                              <button
                                onClick={() =>
                                  handleDeleteLocalImage(image.name)
                                }
                                className={styles.delete}
                              >
                                Delete
                              </button>
                              <button
                                onClick={() => handleSetThumbnail(image.name)}
                              >
                                Set as Thumbnail
                              </button>
                            </div>
                          </div>
                          <select
                            value={imageCategories[image.name] || ''}
                            onChange={(e) =>
                              handleCategoryChange(e, image.name)
                            }
                            className={styles.select}
                          >
                            <option value="" disabled>
                              Select Category
                            </option>
                            {Object.keys(IMAGE_CATEGORY_OPTIONS).map(
                              (category) => (
                                <option key={category} value={category}>
                                  {IMAGE_CATEGORY_OPTIONS[category]}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.imageContainer}>
                <div className={styles.imageRow}>
                  {Object.keys(images).map((category) => (
                    <>
                      {images[category].map((url) => (
                        <div key={url} className={styles.imageWrapper}>
                          <div className={styles.imageOverlayWrapper}>
                            <img
                              src={url}
                              alt={category}
                              className={styles.image}
                            />
                            {newThumbnail === url && (
                              <div className={styles.thumbnailOverlay}>
                                Primary
                              </div>
                            )}
                            <button className={styles.ellipsisButton}>
                              ...
                            </button>
                            <div className={styles.dropdownContent}>
                              <button
                                onClick={() => handleDeleteImage(category, url)}
                                className={styles.delete}
                              >
                                Delete
                              </button>
                              <button onClick={() => handleSetThumbnail(url)}>
                                Set as Thumbnail
                              </button>
                            </div>
                          </div>
                          <select
                            onChange={(e) =>
                              handleCategoryEdit(url, e.target.value)
                            }
                            defaultValue={category}
                            className={styles.select}
                          >
                            {Object.keys(IMAGE_CATEGORY_OPTIONS).map(
                              (category) => (
                                <option key={category} value={category}>
                                  {IMAGE_CATEGORY_OPTIONS[category]}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.flex}>
              {hostPropertyId && onboardingStage >= 2 ? (
                <button
                  style={{
                    padding: '15px',
                    width: '40%',
                    color: 'white',
                    backgroundColor: '#4dcad2',
                    transition: 'opacity 0.9s',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    border: '1px solid black',
                    marginTop: '60px',
                    fontWeight: 'bold',
                  }}
                  onClick={updateHandler}
                >
                  Update
                </button>
              ) : (
                <button
                  style={{
                    padding: '15px',
                    width: '40%',
                    color: 'white',
                    backgroundColor: '#4dcad2',
                    transition: 'opacity 0.9s',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    border: '1px solid black',
                    marginTop: '60px',
                    fontWeight: 'bold',
                  }}
                  onClick={nextHandler}
                >
                  Save
                </button>
              )}
            </div>
          </OnboardingContainer>
        </OnboardingWrapper>
        <BannerWrapper>
          <img src="/images/banner/2_updated.png" alt="Auth page banner" />
        </BannerWrapper>
      </Wrapper>
    </>
  );
};

export default PhotoUpload;
