import React, { useContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_IMAGE_EDIT_PAGE,
  AGENT_PASSWORD_CHANGE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
} from './settings/constant';
import PropertyOfferings from 'containers/PropertyOfferings/PropertyOfferings';
import TitleAndDescription from 'containers/TitleAndDescription/TitleAndDescription';
import Amenities from 'containers/Amenities/Amenities';
import PhotoUpload from 'containers/PhotoUpload/PhotoUpload';
import BookingSettings from 'containers/BookingSettings/BookingSettings';
import Confirmation from 'containers/Confirmation/Confirmation';
import SearchForm from 'components/SearchForm/SearchForm';
import PaymentPage from 'containers/PaymentPage/PaymentPage';
import PaymentResponse from 'containers/PaymentResponse/PaymentResponse';
import Bookings from 'containers/PropertyBookings/Bookings';

// protected route
function RequireAuth({ children }) {
  let { loggedIn } = useContext(AuthContext);
  let location = useLocation();
  if (!loggedIn) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
  }

  return children;
}

// public routes
const HomePage = React.lazy(() => import('containers/Home/Home'));

const ListingPage = React.lazy(() => import('containers/Listing/Listing'));
const SinglePageView = React.lazy(
  () => import('containers/SinglePage/SinglePageView'),
);
const AgentDetailsViewPage = React.lazy(
  () => import('containers/Agent/AccountDetails/AgentDetailsViewPage'),
);
const AgentItemLists = React.lazy(
  () => import('containers/Agent/AccountDetails/AgentItemLists'),
);
const AgentFavItemLists = React.lazy(
  () => import('containers/Agent/AccountDetails/AgentFavItemLists'),
);
const AgentContact = React.lazy(
  () => import('containers/Agent/AccountDetails/AgentContact'),
);
const PricingPage = React.lazy(() => import('containers/Pricing/Pricing'));
const PrivacyPage = React.lazy(() => import('containers/Privacy/Privacy'));

// const LandingPage = React.lazy(() => import('containers/Landing/Landing'));

// const OnBoarding = React.lazy(
//   () => import('containers/TravellerOnboarding/TravellerOnboarding'),
// );

// const Property = React.lazy(()=>import('containers/property/Property'));

const Property = React.lazy(
  () => import('containers/Property/PropertyDetails'),
);

const Structure = React.lazy(() => import('containers/Structure/Structure'));

const PropertyListing = React.lazy(
  () => import('containers/PropertyListing/PropertyListing'),
);

const House = React.lazy(() => import('containers/House/House'));

const Location = React.lazy(() => import('containers/Location/Location'));

// const Property = import('containers/Property/PropertyDetails');

const SignInPage = React.lazy(() => import('containers/Auth/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('containers/Auth/SignUp/SignUp'));
const ForgetPasswordPage = React.lazy(
  () => import('containers/Auth/ForgetPassword'),
);
const NotFound = React.lazy(() => import('containers/404/404'));
// protected route
const AddListingPage = React.lazy(
  () => import('containers/AddListing/AddListing'),
);

const MyBiddingsPage = React.lazy(
  () => import('containers/MyBiddings/MyBiddings'),
);

const HostAdmin = React.lazy(
  () => import('containers/AdminDashboard/TransactionTable'),
);

const HostDetail = React.lazy(
  () => import('containers/AdminDashboard/HostTable'),
);

const AgentAccountSettingsPage = React.lazy(
  () => import('containers/Agent/AccountSettings/AgentAccountSettingsPage'),
);
const AgentCreateOrUpdateForm = React.lazy(
  () => import('containers/Agent/AccountSettings/AgentCreateOrUpdateForm'),
);
const AgentPictureChangeForm = React.lazy(
  () => import('containers/Agent/AccountSettings/AgentPictureChangeForm'),
);
const ChangePassWord = React.lazy(
  () => import('containers/Agent/AccountSettings/ChangePassWordForm'),
);

const PrivacyPolicy = React.lazy(() => import('components/PrivacyPolicy'));

const PaymentTerms = React.lazy(() => import('components/PaymentTerms'));

const TermOfUse = React.lazy(() => import('components/TermOfUse'));
const AboutUs = React.lazy(() => import('components/AboutUs'));

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${SINGLE_POST_PAGE}/:slug`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />
        {/* Nested routes for agent page */}
        <Route
          path={AGENT_PROFILE_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AgentDetailsViewPage />
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_PROFILE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_FAVORITE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentFavItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_CONTACT}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentContact />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Nested routes */}
        <Route
          path={PRICING_PLAN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PricingPage />
            </React.Suspense>
          }
        />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOGIN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignInPage />
            </React.Suspense>
          }
        />

        <Route
          path={REGISTRATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignUpPage />
            </React.Suspense>
          }
        />

        <Route
          path={FORGET_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgetPasswordPage />
            </React.Suspense>
          }
        />
        {/* Protected routes */}
        <Route
          path={ADD_HOTEL_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AddListingPage />
              </RequireAuth>
            </React.Suspense>
          }
        />
        <Route
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AgentAccountSettingsPage />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_ACCOUNT_SETTINGS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentCreateOrUpdateForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_IMAGE_EDIT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentPictureChangeForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PASSWORD_CHANGE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ChangePassWord />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Protected routes*/}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </React.Suspense>
          }
        />
        <Route
          path="/payment-terms"
          element={
            <React.Suspense fallback={<Loader />}>
              <PaymentTerms />
            </React.Suspense>
          }
        />
        <Route
          path="/term-of-use"
          element={
            <React.Suspense fallback={<Loader />}>
              <TermOfUse />
            </React.Suspense>
          }
        />
        <Route
          path="/about-us"
          element={
            <React.Suspense fallback={<Loader />}>
              <AboutUs />
            </React.Suspense>
          }
        />
        <Route
          path={`/property/:slug/:title`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />
        <Route
          path="/customer/my-bids"
          element={
            <React.Suspense fallback={<Loader />}>
              <MyBiddingsPage userType={'CUSTOMER'} />
            </React.Suspense>
          }
        />

        <Route
          path="/paymentConfirmation"
          element={
            <React.Suspense fallback={<Loader />}>
              <PaymentPage />
            </React.Suspense>
          }
        />

        <Route
          path="/bookingConfirmation"
          element={
            <React.Suspense fallback={<Loader />}>
              <PaymentResponse />
            </React.Suspense>
          }
        />

        <Route
          path="/customer/myBookings"
          element={
            <React.Suspense fallback={<Loader />}>
              <Bookings userType={'CUSTOMER'} />
            </React.Suspense>
          }
        />

        <Route
          path="/host/myBookings"
          element={
            <React.Suspense fallback={<Loader />}>
              <Bookings userType={'HOST'} />
            </React.Suspense>
          }
        />

        <Route
          path="/host/my-bids"
          element={
            <React.Suspense fallback={<Loader />}>
              <MyBiddingsPage userType={'HOST'} />
            </React.Suspense>
          }
        />
      </Route>

      <Route
        path="/property-registration"
        element={
          <React.Suspense fallback={<Loader />}>
            <Property />
          </React.Suspense>
        }
      />

      <Route
        path="/description"
        element={
          <React.Suspense fallback={<Loader />}>
            <Structure />
          </React.Suspense>
        }
      />
      <Route
        path="/house"
        element={
          <React.Suspense fallback={<Loader />}>
            <House />
          </React.Suspense>
        }
      />
      <Route
        path="/booking-settings"
        element={
          <React.Suspense fallback={<Loader />}>
            <BookingSettings />
          </React.Suspense>
        }
      />
      <Route
        path="/acknowledgement"
        element={
          <React.Suspense fallback={<Loader />}>
            <Confirmation />
          </React.Suspense>
        }
      />
      <Route
        path="/location"
        element={
          <React.Suspense fallback={<Loader />}>
            <Location />
          </React.Suspense>
        }
      />
      <Route
        path="/property-offerings"
        element={
          <React.Suspense fallback={<Loader />}>
            <PropertyOfferings />
          </React.Suspense>
        }
      />
      <Route
        path="/title-and-description"
        element={
          <React.Suspense fallback={<Loader />}>
            <TitleAndDescription />
          </React.Suspense>
        }
      />
      <Route
        path="/amenities"
        element={
          <React.Suspense fallback={<Loader />}>
            <Amenities />
          </React.Suspense>
        }
      />
      <Route
        path="/property-photos"
        element={
          <React.Suspense fallback={<Loader />}>
            <PhotoUpload />
          </React.Suspense>
        }
      />
      <Route
        path="/photo-upload"
        element={
          <React.Suspense fallback={<Loader />}>
            <PhotoUpload />
          </React.Suspense>
        }
      />
      <Route
        path="/property-listing"
        element={
          <React.Suspense fallback={<Loader />}>
            <PropertyListing />
          </React.Suspense>
        }
      />
      {/* <Route
        path="/landing"
        element={
          <React.Suspense fallback={<Loader />}>
            <LandingPage></LandingPage>
          </React.Suspense>
        }
      /> */}
      {/* <Route
        path="/onboard"
        element={
          <React.Suspense fallback={<Loader />}>
            <OnBoarding></OnBoarding>
          </React.Suspense>
        }
      /> */}
      <Route
        path="/search-location"
        element={
          <React.Suspense fallback={<Loader />}>
            <SearchForm />
          </React.Suspense>
        }
      />
      <Route
        path="/b23y@s"
        element={
          <React.Suspense fallback={<Loader />}>
            <HostAdmin />
          </React.Suspense>
        }
      />
      <Route
        path="/b24y@s"
        element={
          <React.Suspense fallback={<Loader />}>
            <HostDetail />
          </React.Suspense>
        }
      />
    </Routes>
  );
}
