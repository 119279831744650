import React, { useState, useEffect } from 'react';
import styles from './Amenities.module.css';
import Logo from 'components/UI/Logo/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import PropertyService from '../../service/PropertyService';
import Wrapper, {
  BannerWrapper,
  OnboardingContainer,
  OnboardingWrapper,
} from '../Auth/Auth.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAmenities,
  addAmenitiesObject,
  setPropertyId,
  updateOnboardingStage,
} from 'features/placeSlice';
import { notification } from 'antd';

const Amenities = () => {
  let navigate = useNavigate();

  const [data, setData] = useState([]);
  const { amenities, amenitiesObject, onboardingStage } = useSelector(
    (select) => select,
  );
  const [features, setFeatures] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const finalResult = useSelector((state) => state);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const hostPropertyId = query.get('id');
  const isEdit =
    hostPropertyId !== 'null' &&
    hostPropertyId !== null &&
    hostPropertyId !== undefined
      ? true
      : false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error]);

  useEffect(() => {
    // Call the getData function from ApiService
    PropertyService.getAmenities()
      .then((responseData) => {
        setData(responseData.result);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (amenities.length > 0) {
      setFeatures(amenities);
      setAllFeatures(amenitiesObject);
    }
  }, [amenities, amenitiesObject]);

  const backHandler = () => {
    dispatch(addAmenities(features));
    if (isEdit) {
      navigate(`/title-and-description?id=${hostPropertyId}`);
      return;
    }
    navigate('/title-and-description');
  };

  const updateHandler = async () => {
    if (!features.length > 0) {
      setError('Please select at least one amenity');
      return;
    }
    dispatch(addAmenities(features));
    dispatch(addAmenitiesObject(allFeatures));
    try {
      const finalAmenities = allFeatures.map((type) => {
        return { id: type.id, description: type.name };
      });
      const finalPropertyDetails = {
        propertyId: hostPropertyId || localStorage.getItem('id'),
        title: finalResult?.detail?.title,
        description: finalResult?.detail?.description,
        bnbUrl: finalResult?.detail?.bnbUrl,
        hostId: localStorage.getItem('userDetails')
          ? JSON.parse(localStorage.getItem('userDetails')).id
          : 0,
        propertyTypeId: finalResult?.place?.id,
        propertyOffering: {
          id: finalResult?.type?.id,
          description: finalResult?.subType?.name,
        },
        details: Object.keys(finalResult?.offerings).map((offering) => {
          return {
            propertyDetailsId: offering,
            detailsValue: finalResult?.offerings[offering],
          };
        }),
        location: {
          cityId:
            finalResult?.location?.cityObject?.id ||
            finalResult?.location?.cityId,
          zoneId:
            finalResult?.location?.cityObject?.zoneId ||
            finalResult?.location?.zoneId,
          stateId: finalResult?.location.state,
          pincode: finalResult?.location?.pincode,
          addressLine1: finalResult?.location?.addressLine1,
          addressLine2: finalResult?.location?.addressLine2,
          landmark: finalResult?.location?.landmark,
          locality: finalResult?.location?.district,
          latitude: finalResult?.location?.coordinates.lat,
          longitude: finalResult?.location?.coordinates.lng,
        },
        amenities: finalAmenities,
      };
      const res = await PropertyService.updateProperty(finalPropertyDetails);

      if (
        res.responseInformation &&
        res.responseInformation?.responseCode === 0
      ) {
        notification.success({
          message: 'Data update so far',
          description: 'Please continue with filling other important details.',
        });
      } else {
        notification.error({
          message: 'Oops! Failed to save data.',
          description: 'Some error occured. Please try again later.',
        });
        return;
      }
      if (res.result && res.result.propertyId) {
        dispatch(setPropertyId(res.result.propertyId));
        navigate(`/acknowledgement?id=${hostPropertyId || localStorage.getItem('id')}`);
      }
    } catch (e) {
      notification.error({
        message: 'Oops! Failed to save data.',
        description: 'Some error occured. Please try again later.',
      });
      return;
    }
  };

  const nextHandler = async (e) => {
    if (!features.length > 0) {
      setError('Please select at least one amenity');
      return;
    }
    dispatch(addAmenities(features));
    dispatch(addAmenitiesObject(allFeatures));
    try {
      const finalAmenities = allFeatures.map((type) => {
        return { id: type.id, description: type.name };
      });
      const finalPropertyDetails = {
        title: finalResult?.detail?.title,
        description: finalResult?.detail?.description,
        bnbUrl: finalResult?.detail?.bnbUrl,
        hostId: localStorage.getItem('userDetails')
          ? JSON.parse(localStorage.getItem('userDetails')).id
          : 0,
        propertyTypeId: finalResult?.place?.id,
        propertyOffering: {
          id: finalResult?.type?.id,
          description: finalResult?.subType?.name,
        },
        details: Object.keys(finalResult?.offerings).map((offering) => {
          return {
            propertyDetailsId: offering,
            detailsValue: finalResult?.offerings[offering],
          };
        }),
        location: {
          cityId:
            finalResult?.location?.cityObject?.id ||
            finalResult?.location?.cityId,
          zoneId:
            finalResult?.location?.cityObject?.zoneId ||
            finalResult?.location?.zoneId,
          stateId: finalResult?.location.state,
          pincode: finalResult?.location?.pincode,
          addressLine1: finalResult?.location?.addressLine1,
          addressLine2: finalResult?.location?.addressLine2,
          landmark: finalResult?.location?.landmark,
          locality: finalResult?.location?.district,
          latitude: finalResult?.location?.coordinates?.lat,
          longitude: finalResult?.location?.coordinates?.lng,
        },
        amenities: finalAmenities,
        onboardingStage: 1,
      };
      const res = await PropertyService.saveProperty(finalPropertyDetails);

      if (
        res.responseInformation &&
        res.responseInformation?.responseCode === 0
      ) {
        localStorage.setItem('id', res?.result?.propertyId);
        notification.success({
          message: 'Data saved so far',
          description: 'Please continue with filling other important details.',
        });
      } else {
        notification.error({
          message: 'Oops! Failed to save data.',
          description: 'Some error occured. Please try again later.',
        });
        return;
      }
      if (res.result && res.result.propertyId) {
        dispatch(setPropertyId(res.result.propertyId));
        dispatch(updateOnboardingStage(1));
        navigate('/property-photos');
      }
      if (hostPropertyId) {
        navigate(`/acknowledgement?id=${hostPropertyId}`);
        return;
      }
      navigate('/property-photos');
    } catch (e) {
      notification.error({
        message: 'Oops! Failed to save data.',
        description: 'Some error occured. Please try again later.',
      });
      return;
    }
  };

  const addItems = (amenity) => {
    if (!features.includes(amenity.name)) {
      setFeatures([...features, amenity.name]);
      setAllFeatures([...allFeatures, amenity]);
      setError('');
    } else {
      setFeatures(features.filter((item) => item !== amenity.name));
      setAllFeatures(allFeatures.filter((item) => item.name !== amenity.name));
    }
  };

  return (
    <Wrapper>
      <OnboardingWrapper>
        <div style={{ margin: '18px' }}>
          <Logo
            withLink
            linkTo="/"
            src="/images/bys_final_logo.png"
            title="Bid your Stay"
          />
        </div>
        <OnboardingContainer>
          <h1>Make your property stand out!.</h1>
          <p style={{ textAlign: 'center', marginBottom: 40 }}>
            List down all the amenities you can offer
          </p>
          <div className={styles.grid}>
            {data?.length > 0 &&
              data?.map((type, index) => (
                <div
                  key={index}
                  className={`${styles.card} ${features.includes(type.name) ? styles.selected : ''}`}
                  onClick={() => addItems(type)}
                >
                  <img
                    src={`/images/${type.icon}.png`}
                    alt={type.name}
                    className={styles.icon}
                  />
                  <p style={{ marginBottom: '0px' }}>{type.name}</p>
                </div>
              ))}
          </div>

          {error && (
            <div
              style={{
                color: 'red',
                marginTop: '20px',
              }}
            >
              {error}
            </div>
          )}

          <div className={styles.flex}>
            <button
              style={{
                padding: '15px',
                width: '40%',
                border: '1px solid #4dcad2',
                color: '#4dcad2',
                transition: 'opacity 0.9s',
                cursor: 'pointer',
                borderRadius: '8px',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={backHandler}
            >
              Back
            </button>

            {isEdit && (hostPropertyId || onboardingStage >= 1) ? (
              <button
                style={{
                  padding: '15px',
                  width: '40%',
                  color: 'white',
                  backgroundColor: '#4dcad2',
                  transition: 'opacity 0.9s',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  border: '1px solid black',
                  marginTop: '60px',
                  fontWeight: 'bold',
                }}
                onClick={updateHandler}
              >
                Update
              </button>
            ) : (
              <button
                style={{
                  padding: '15px',
                  width: '40%',
                  color: 'white',
                  backgroundColor: '#4dcad2',
                  transition: 'opacity 0.9s',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  border: '1px solid black',
                  marginTop: '60px',
                  fontWeight: 'bold',
                }}
                onClick={nextHandler}
              >
                Save
              </button>
            )}
          </div>
        </OnboardingContainer>
      </OnboardingWrapper>

      <BannerWrapper>
        <img src="/images/banner/6_updated.png" alt="Auth page banner" />
      </BannerWrapper>
    </Wrapper>
  );
};

export default Amenities;
