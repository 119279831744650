import { Offerings, PriceArea } from 'components/GridCard/GridCard.style';
import { CardWrapper } from './BookingCard.style';
import { formatCurrencyAmount } from 'containers/Confirmation/utils';
import React from 'react';

const BookingCard = ({ key, bookings }) => {
  return (
    <CardWrapper key={key}>
      <div className="card-image">
        <img src={bookings?.thumbnailUrl} alt={bookings?.propertyTitle} />
      </div>
      <div className="card-content">
        <div className="card-header">
          <div className="card-title">{bookings?.propertyTitle}</div>
        </div>
        <div className="card-details">
          <div className="title-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: '15px',
                    color: '#726868',
                    marginBottom: '10px',
                  }}
                >
                  Transaction Id :{' '}
                  <PriceArea>{bookings?.transactionId}</PriceArea>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: '15px',
                    color: '#726868',
                    marginBottom: '10px',
                  }}
                >
                  Status : <PriceArea>{bookings?.status}</PriceArea>
                </div>
                {bookings?.checkIn && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      fontSize: '15px',
                      color: '#726868',
                      marginBottom: '10px',
                    }}
                  >
                    Check-in Date : <PriceArea>{bookings?.checkIn} </PriceArea>
                  </div>
                )}
                {bookings?.checkIn && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      fontSize: '15px',
                      color: '#726868',
                      marginBottom: '10px',
                    }}
                  >
                    Check-out Date : <PriceArea>{bookings?.checkOut}</PriceArea>
                  </div>
                )}

                {(bookings?.adults ||
                  bookings?.children ||
                  bookings?.pets ||
                  bookings?.infants) && (
                  <Offerings>
                    Guests:{' '}
                    {[
                      bookings?.adults && `Adults : ${bookings.adults}`,
                      bookings?.children && `Children : ${bookings.children}`,
                      bookings?.infants && `Infants : ${bookings.infants}`,
                      bookings?.pets && `Pets : ${bookings.pets}`,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </Offerings>
                )}

                <div className="price-container">
                  <div className="price-details">
                    <span className="price-label">Total Price :</span>
                    <PriceArea className="price">
                      {formatCurrencyAmount.format(bookings?.baseAmount)}
                    </PriceArea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default BookingCard;
