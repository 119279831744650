import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './PaymentPage.css';
import {
  dateDifference,
  formatCurrencyAmount,
} from 'containers/Confirmation/utils';
import { Button, Divider, Select, notification } from 'antd';
import { FaArrowRight } from 'react-icons/fa';
import Heading from 'components/UI/Heading/Heading';
import useWindowSize from 'library/hooks/useWindowSize';
import moment from 'moment';
import PaymentService from 'service/PaymentService';
import DOMPurify from 'dompurify';

const options = [
  {
    value: '1',
    label: 'Aadhar No.',
  },
  {
    value: '2',
    label: 'Pan Card No.',
  },
  {
    value: '3',
    label: 'Passport No.',
  },
  {
    value: '4',
    label: 'Driving Licence No.',
  },
  {
    value: '5',
    label: 'Voter Id No.',
  },
];

const PaymentPage = () => {
  const [loader, setloader] = useState(false);
  const { width } = useWindowSize();
  const [identityType, setIndentitytype] = useState();
  const [guestDetails, setGuestDetails] = useState({
    name: '',
    email: '',
    phone: '',
    documentType: '',
    documentNumber: '',
  });
  const acceptBidResponse = useSelector((state) => state.acceptBidResponse);
  const confirmationHandler = () => {
    if (
      !guestDetails?.name ||
      !guestDetails?.phone ||
      !guestDetails?.email ||
      !guestDetails?.documentType ||
      !guestDetails?.documentNumber
    ) {
      notification.error({
        message: 'Please Provide details.',
        description: 'Please provide traveller details properly.',
        placement: 'bottomRight',
      });
      return;
    }
    setloader(true);
    const data = {
      bookingId: acceptBidResponse?.bookingId,
      amount:
        acceptBidResponse?.baseAmount +
        acceptBidResponse?.serviceFeeAmount +
        acceptBidResponse?.taxAmount +
        (acceptBidResponse?.additionalChargesAmount || 0) -
        (acceptBidResponse?.discountAmount || 0),
      guestDetails: {
        guestName: guestDetails?.name,
        email: guestDetails?.email,
        phoneNumber: guestDetails?.phone,
        documentType: guestDetails?.documentType,
        documentNumber: guestDetails?.documentNumber,
      },
    };
    setloader(false);

    PaymentService.initiatePaymentForBooking(data)
      .then((res) => {
        window.location.replace(res.result.redirectUrl);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
        notification.error({
          message: 'Oops! Something went wrong.',
          description: 'Some error occured. Please try again later.',
          placement: 'bottomRight',
        });
      });
  };

  const daysInterval = dateDifference(
    acceptBidResponse?.checkIn,
    acceptBidResponse?.checkOut,
  );

  const handleInputChange = (value, key) => {
    if (key === 'documentType') {
      if (value === '1') {
        setIndentitytype('Aadhar No. ');
        value = 'AADHAAR';
      } else if (value === '2') {
        setIndentitytype('Pan Card No. ');
        value = 'PAN CARD';
      } else if (value === '3') {
        setIndentitytype('Passport No. ');
        value = 'PASSPORT';
      } else if (value === '4') {
        setIndentitytype('Driving License No. ');
        value = 'DRIVING LICENSE';
      } else if (value === '5') {
        setIndentitytype('Voter Id No. ');
        value = 'VOOTER ID';
      }
    }
    const sanitizedValue = DOMPurify.sanitize(value);
    setGuestDetails((prev) => ({
      ...prev,
      [key]: sanitizedValue,
    }));
  };

  return (
    <div className="container">
      <div className="content">
        {width >= 750 && (
          <Heading
            as="h1"
            textAlign={width > 500 ? 'left' : 'center'}
            content={'Confirm and pay'}
            style={{
              marginBottom: width > 500 ? '20px' : '20px',
            }}
          />
        )}
        <div className="trip-details">
          <div className="trip-item">
            <span className="label">Check In: </span>
            <span className="value">
              {/* {acceptBidResponse?.checkIn}{' '} */}
              {moment(acceptBidResponse?.checkIn).format('DD-MMM-YYYY')}
            </span>
            {/* <a href="#edit" className="edit-link">
              Edit
            </a> */}
          </div>
          <div className="trip-item">
            <span className="label">Check Out: </span>
            <span className="value">
              {moment(acceptBidResponse?.checkOut).format('DD-MMM-YYYY')}
            </span>
          </div>
          <div className="trip-item">
            <span className="label">Guests: </span>
            <span className="value">
              {acceptBidResponse?.adults +
                acceptBidResponse?.infants +
                acceptBidResponse?.children +
                acceptBidResponse?.pets}{' '}
              guest
            </span>
          </div>

          <div className="trip-item">
            <span className="label">Bid/listed price : </span>
            <span className="value">
              {`${formatCurrencyAmount.format(acceptBidResponse?.baseAmount)}/ Night`}
            </span>
          </div>
          <Divider style={{ margin: '30px 0px' }}>
            <span style={{ color: '#949090' }}>
              Please Provide Traveller Detail
            </span>
          </Divider>
          <div className="user-detail">
            <span className="label">Name:</span>
            <input
              type="text"
              className="input-field"
              placeholder="Traveller Name"
              onChange={(e) => handleInputChange(e.target.value, 'name')}
            />
          </div>
          <div className="user-detail">
            <span className="label">Email:</span>
            <input
              type="email"
              className="input-field"
              placeholder="Traveller Email"
              onChange={(e) => handleInputChange(e.target.value, 'email')}
            />
          </div>
          <div className="user-detail">
            <span className="label">Mobile No:</span>
            <input
              type="text"
              className="input-field"
              placeholder="Traveller Mobile No"
              onChange={(e) => handleInputChange(e.target.value, 'phone')}
            />
          </div>
          <div className="user-detail">
            <span className="label">Select Identity Type:</span>
            <Select
              placeholder="Identity Type"
              className="select"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{
                width: '100%',
                textAlign: 'left',
                height: '42px',
              }}
              options={options}
              onChange={(label) => handleInputChange(label, 'documentType')}
            />
          </div>
          {identityType && (
            <div className="user-detail">
              <span className="label">{identityType}:</span>
              <input
                type="text"
                className="input-field"
                placeholder="Traveller Identity No"
                onChange={(e) =>
                  handleInputChange(e.target.value, 'documentNumber')
                }
              />
            </div>
          )}

          <Button
            type="primary"
            className="pay-btn"
            onClick={confirmationHandler}
            loading={loader}
          >
            Make payment
            <FaArrowRight />
          </Button>
        </div>
      </div>
      <div className="sidebar">
        <div className="sticky-card">
          <img
            src={acceptBidResponse?.thumbnailUrl}
            alt="Wooded Luxury Attic Suite"
            className="card-image"
          />
          <div className="card-details">
            <h3> {acceptBidResponse?.propertyTitle}</h3>
          </div>
          <div className="pricing">
            <div className="price-item">
              <span>Base Price for {daysInterval} nights</span>
              <span>
                {formatCurrencyAmount.format(acceptBidResponse?.baseAmount)}
              </span>
            </div>
            {!!acceptBidResponse?.additionalChargesAmount && (
              <div className="price-item">
                <span>Additional Charges</span>
                <span>
                  {formatCurrencyAmount.format(
                    acceptBidResponse?.additionalChargesAmount,
                  )}
                </span>
              </div>
            )}
            <div className="price-item">
              <span>Taxes</span>
              <span>
                {formatCurrencyAmount.format(acceptBidResponse?.taxAmount)}
              </span>
            </div>
            <div className="price-item">
              <span>BYS service fee</span>
              <span>
                {formatCurrencyAmount.format(
                  acceptBidResponse?.serviceFeeAmount,
                )}
              </span>
            </div>
            {!!acceptBidResponse?.discountAmount && (
              <div className="price-item">
                <span>Discount</span>
                <span>
                  -{' '}
                  {formatCurrencyAmount.format(
                    acceptBidResponse?.discountAmount,
                  )}
                </span>
              </div>
            )}
            <div className="total">
              <span>Total (INR)</span>
              <span>
                {formatCurrencyAmount.format(
                  acceptBidResponse?.baseAmount +
                    acceptBidResponse?.serviceFeeAmount +
                    acceptBidResponse?.taxAmount +
                    (acceptBidResponse?.additionalChargesAmount || 0) -
                    (acceptBidResponse?.discountAmount || 0),
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {width < 750 && (
        <Heading
          as="h2"
          textAlign={width > 500 ? 'left' : 'center'}
          content={'Confirm and pay'}
          style={{
            marginBottom: width > 500 ? '20px' : '0px',
          }}
        />
      )}
    </div>
  );
};

export default PaymentPage;
