import React, { useEffect, useState } from 'react';
import styles from './BookingSettings.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Wrapper, {
  Title,
  OnboardingWrapper,
  BannerWrapper,
} from '../Auth/Auth.style';
import { useDispatch, useSelector } from 'react-redux';
import { addBookingSettings, updateOnboardingStage } from 'features/placeSlice';
import BookingService from 'service/BookingService';
import { notification } from 'antd';
import Navbar from 'components/UI/Navbar/Navbar';

const defaultCancellationPolicyValues = [
  {
    label: 'Checkin within 4 hours or less',
    text: 'Upto4H',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
  {
    label: 'Checkin between 4 hours to 12 hours',
    text: 'Upto12H',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
  {
    label: 'Checkin between 12 hours to 24 hours',
    text: 'Upto24H',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
  {
    label: 'Checkin between 24 hours to 72 hours',
    text: 'Upto72H',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
  {
    label: 'Checkin between 3 days to 7 days',
    text: 'Upto7D',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
  {
    label: 'Checkin from 7 days or more',
    text: 'MoreThan7D',
    value: '100',
    isNonRefundable: false,
    isFullRefund: true,
  },
];

const hourDurationMap = {
  Upto4H: {
    validFromHoursBefore: 0,
    validFromHoursUntil: 4,
  },
  Upto12H: {
    validFromHoursBefore: 4,
    validFromHoursUntil: 12,
  },
  Upto24H: {
    validFromHoursBefore: 12,
    validFromHoursUntil: 24,
  },
  Upto72H: {
    validFromHoursBefore: 24,
    validFromHoursUntil: 72,
  },
  Upto7D: {
    validFromHoursBefore: 72,
    validFromHoursUntil: 7 * 24,
  },
  MoreThan7D: {
    validFromHoursBefore: 7 * 24,
    validFromHoursUntil: -1,
  },
};

function BookingSettings() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const hostPropertyId = query.get('id');

  const [error, setError] = useState({
    checkInTime: '',
    checkOutTime: '',
    bookingApproval: '',
    maxDayBooking: '',
    minDayBooking: '',
  });

  const [bookingDetails, setBookingDetails] = useState({
    checkInTime: '',
    checkOutTime: '',
    bookingApproval: '',
    maxDayBooking: '',
    minDayBooking: '',
    cancellationPolicy: {
      Upto4H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto12H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto24H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto72H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto7D: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      MoreThan7D: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
    },
    restrictions: [],
  });
  const [cancellationPolicyValues, setCancellationPolicyValues] = useState(
    defaultCancellationPolicyValues,
  );
  const restrictions = [
    { label: 'No Alcohol' },
    { label: 'No Smoking' },
    { label: 'No Firecrackers' },
    { label: 'No Illegal Activities' },
    { label: 'No Events' },
    { label: 'No Parties' },
  ];
  const { bookingSettings, propertyId, onboardingStage } = useSelector(
    (state) => ({
      bookingSettings: state.bookingSettings,
      propertyId: state.propertyId,
      onboardingStage: state.onboardingStage,
    }),
  );

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setBookingDetails(bookingSettings);
  }, [bookingSettings]);

  useEffect(() => {
    setBookingDetails((prev) => {
      const updatedCancellationPolicy = { ...prev.cancellationPolicy };
      cancellationPolicyValues.forEach((item) => {
        updatedCancellationPolicy[item.text] = {
          fullRefund: item.isFullRefund,
          noRefund: item.isNonRefundable,
          partialRefund: item.value,
        };
      });

      return {
        ...prev,
        cancellationPolicy: updatedCancellationPolicy,
      };
    });
  }, [cancellationPolicyValues]);

  const handleBookingChange = (e) => {
    setError((prev) => ({
      ...prev,
      bookingApproval: '',
    }));

    setBookingDetails((prev) => ({
      ...prev,
      bookingApproval: e.target.value,
    }));
  };

  const handleTimeChange = (value, key) => {
    if (value) {
      setError((prev) => ({ ...prev, [key]: '' }));
    }
    setBookingDetails((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const validateEntries = () => {
    let newErrors = {};

    if (!bookingDetails.checkInTime) {
      newErrors.checkInTime = 'Please set checkin time';
    }
    if (!bookingDetails.minDayBooking) {
      newErrors.minDayBooking = 'Please enter min day';
    }
    if (!bookingDetails.maxDayBooking) {
      newErrors.maxDayBooking = 'Please enter max day';
    }
    if (!bookingDetails.checkOutTime) {
      newErrors.checkOutTime = 'Please set checkout time';
    }
    if (!bookingDetails.bookingApproval) {
      newErrors.bookingApproval = 'Please select anyone of them';
    }

    if (newErrors) setError(newErrors);

    if (
      !bookingDetails.checkInTime ||
      !bookingDetails.checkOutTime ||
      !bookingDetails.bookingApproval ||
      !bookingDetails.minDayBooking ||
      !bookingDetails.maxDayBooking
    )
      return true;
    return false;
  };

  const nextHandler = async () => {
    if (validateEntries()) {
      scrollToTop();
      return;
    }

    const cancellationRules = Object.keys(
      bookingDetails.cancellationPolicy,
    ).map((key) => {
      return {
        ...hourDurationMap[key],
        refundPercentage: bookingDetails.cancellationPolicy[key].partialRefund,
      };
    });

    dispatch(addBookingSettings(bookingDetails));
    try {
      const finalBookingSetting = {
        propertyId: propertyId,
        checkInTime: bookingDetails.checkInTime,
        checkOutTime: bookingDetails.checkOutTime,
        minDayBooking: bookingDetails.minDayBooking,
        maxDayBooking: bookingDetails.maxDayBooking,
        cancellationPolicy: JSON.stringify(cancellationRules),
        propertyPolicy: JSON.stringify(bookingDetails.restrictions),
        onboardingStage: 4,
        approvalRequiredForBooking:
          bookingDetails.bookingApproval === 'onApproval' ? true : false,
      };
      const res =
        await BookingService.savePropertyBookingSettings(finalBookingSetting);

      if (
        res.responseInformation &&
        res.responseInformation?.responseCode === 0
      ) {
        notification.success({
          message: 'Woohoo, you are very close to complete your onboarding.',
          description: 'Please review all details and confirm',
        });
        dispatch(updateOnboardingStage(4));
        navigate('/acknowledgement');
      } else {
        notification.error({
          message: 'Oops! Failed to save data.',
          description: 'Some error occured. Please try again later.',
        });
        return;
      }
    } catch (error) {
      return;
    }
  };

  // const backHandler = () => {
  //   dispatch(addBookingSettings(bookingDetails));
  //   navigate('/property-listing');
  // };

  const updateFullRefund = (index) => {
    const updatedPolicy = [...cancellationPolicyValues];
    updatedPolicy[index].isNonRefundable = updatedPolicy[index].isFullRefund;
    updatedPolicy[index].isFullRefund = !updatedPolicy[index].isFullRefund;
    updatedPolicy[index].value = 100;
    setCancellationPolicyValues(updatedPolicy);
  };

  const updateNoRefund = (index) => {
    const updatedPolicy = [...cancellationPolicyValues];
    updatedPolicy[index].isFullRefund = updatedPolicy[index].isNonRefundable;
    updatedPolicy[index].isNonRefundable =
      !updatedPolicy[index].isNonRefundable;
    updatedPolicy[index].value = 0;
    setCancellationPolicyValues(updatedPolicy);
  };

  const addItemsHandle = (restriction) => {
    if (!bookingDetails.restrictions.includes(restriction))
      setBookingDetails((prev) => ({
        ...prev,
        restrictions: [...prev.restrictions, restriction],
      }));
    else {
      // const updatedRestriction = bookingDetails.restrictions.filter(
      //   (item) => item !== restriction,
      // );
      setBookingDetails((prev) => ({
        ...prev,
        restrictions: [
          ...prev.restrictions.filter((item) => item !== restriction),
        ],
      }));
    }
  };

  const handleInputChange = (value, type) => {
    if (value) {
      setError((prev) => ({
        ...prev,
        [type]: '',
      }));
    }
    setBookingDetails((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <Wrapper>
      <OnboardingWrapper>
        <Navbar />

        <div style={{ textAlign: 'center' }}>
          <Title>Reservation Settings </Title>
        </div>

        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.step2}>
              <div className={styles.content}>
                <h2>Set Checkin Time</h2>
                <input
                  type="time"
                  name="timePicker"
                  value={bookingDetails.checkInTime}
                  onChange={(e) =>
                    handleTimeChange(e.target.value, 'checkInTime')
                  }
                />
                {error.checkInTime && (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '2px',
                      marginBottom: '2px',
                    }}
                  >
                    {error.checkInTime}
                  </div>
                )}
              </div>
              <div className={styles.content}>
                <h2>Set Checkout Time</h2>
                <input
                  type="time"
                  name="timePicker"
                  value={bookingDetails.checkOutTime}
                  onChange={(e) =>
                    handleTimeChange(e.target.value, 'checkOutTime')
                  }
                />
                {error.checkOutTime && (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '2px',
                      marginBottom: '2px',
                    }}
                  >
                    {error.checkOutTime}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.step}>
              <div className={styles.content}>
                <h2>Booking Approval</h2>
                <p
                  style={{
                    marginBottom: '24px',
                    marginTop: '2px',
                    textAlign: 'left',
                  }}
                >
                  (It is recommended to keep instant booking on for quicker
                  confirmation and for avoiding loss due to delay in approval.
                  This is only for bookings on Base price or your approved
                  discount type.)
                </p>
              </div>
              <div style={{ marginLeft: '14px' }}>
                <label className={styles.bookingContent}>
                  <span className={styles.bookingText}>Instant</span>
                  <input
                    type="radio"
                    value="instant"
                    checked={bookingDetails.bookingApproval === 'instant'}
                    onChange={handleBookingChange}
                  />
                </label>
                <label className={styles.bookingContent}>
                  <span className={styles.bookingText}>On Approval</span>
                  <input
                    type="radio"
                    value="onApproval"
                    checked={bookingDetails.bookingApproval === 'onApproval'}
                    onChange={handleBookingChange}
                  />
                </label>
              </div>

              {error.bookingApproval && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '2px',
                    marginBottom: '2px',
                  }}
                >
                  {error.bookingApproval}
                </div>
              )}
            </div>

            <div className={styles.step}>
              <div className={styles.labelinputSlider}>
                <label>Minimum Day of Booking :</label>
                <input
                  type="number"
                  value={bookingDetails.minDayBooking}
                  style={{ marginTop: '0px', width: '200px' }}
                  placeholder="Enter days in digits(e.g., 3, 5, etc.)"
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'minDayBooking')
                  }
                />
              </div>
              {error.minDayBooking && (
                <div
                  style={{
                    color: 'red',
                    marginBottom: '10px',
                    marginLeft: '10px',
                  }}
                >
                  {error.minDayBooking}
                </div>
              )}
              <div className={styles.labelinputSlider}>
                <label>Maximum Day of Booking</label>
                <input
                  type="number"
                  placeholder="Enter days in digits(e.g., 3, 5, etc.)"
                  style={{ marginTop: '0px', width: '200px' }}
                  value={bookingDetails.maxDayBooking}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'maxDayBooking')
                  }
                />
              </div>
              {error.maxDayBooking && (
                <div
                  style={{
                    color: 'red',
                    marginLeft: '10px',
                  }}
                >
                  {error.maxDayBooking}
                </div>
              )}
            </div>

            <div className={styles.divider}></div>
            <div className={styles.step}>
              <div className={styles.content}>
                <h2 style={{ marginBottom: '20px' }}>
                  Select your cancellation policy
                </h2>
              </div>
              {cancellationPolicyValues.map(
                (cancellationPolicyValue, index) => {
                  return (
                    <div className={styles.cancellationContentMain} key={index}>
                      <span className={styles.cancellationText}>
                        {cancellationPolicyValue.label}
                      </span>
                      <div className={styles.cancellationContent}>
                        <div className={styles.radio}>
                          <input
                            type="radio"
                            id={`15DaysFullRefund-${index}`}
                            name={`15DaysRefundOption-${index}`}
                            value={cancellationPolicyValue.isFullRefund}
                            checked={!!cancellationPolicyValue.isFullRefund}
                            onChange={() => {
                              updateFullRefund(index);
                            }}
                          />
                          <label htmlFor="15DaysFullRefund">Full Refund</label>
                        </div>
                        <div className={styles.radio}>
                          <input
                            type="radio"
                            id={`15DaysNoRefund-${index}`}
                            name={`15DaysRefundOption-${index}`}
                            value={cancellationPolicyValue.isNonRefundable}
                            checked={!!cancellationPolicyValue.isNonRefundable}
                            onChange={() => {
                              updateNoRefund(index);
                            }}
                          />
                          <label htmlFor="15DaysNoRefund">No Refund</label>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>
                            <input
                              type="number"
                              id="partialRefund"
                              min={0}
                              max={100}
                              className={styles.inputBox}
                              value={Number(cancellationPolicyValue.value)}
                              onChange={(e) => {
                                if (e.target.value <= 100) {
                                  const updatedPolicy = [
                                    ...cancellationPolicyValues,
                                  ];
                                  updatedPolicy[index].value = e.target.value;
                                  updatedPolicy[index].isFullRefund = false;
                                  updatedPolicy[index].isNonRefundable = false;
                                  setCancellationPolicyValues(updatedPolicy);
                                }
                              }}
                            />
                            %
                          </span>
                          <label htmlFor="partialRefund">Partial Refund</label>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            <div className={styles.divider}></div>
            <div className={styles.step}>
              <div className={styles.content}>
                <h2>Restrictions (Multi select boxes)</h2>
              </div>
              <div className={styles.grid}>
                {restrictions.map((type, index) => (
                  <div
                    key={index}
                    className={`${styles.card} ${bookingDetails.restrictions.includes(type.label) ? styles.selected : ''}`}
                    onClick={() => addItemsHandle(type.label)}
                  >
                    {type.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.flex}>
              {/* <button className={styles.backBtn} onClick={backHandler}>
                Back
              </button> */}
              {hostPropertyId && onboardingStage > 3 ? (
                <button
                  style={{
                    padding: '15px',
                    width: '40%',
                    color: 'white',
                    backgroundColor: '#4dcad2',
                    transition: 'opacity 0.9s',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    border: '1px solid black',
                    marginTop: '60px',
                    fontWeight: 'bold',
                  }}
                  onClick={nextHandler}
                >
                  Update
                </button>
              ) : (
                <button className={styles.nextBtn} onClick={nextHandler}>
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </OnboardingWrapper>
      <BannerWrapper>
        <img src="/images/banner/4_updated.png" alt="Auth page banner" />
      </BannerWrapper>
    </Wrapper>
  );
}

export default BookingSettings;
